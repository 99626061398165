import React, { useEffect, useState } from "react";
import { apiSecure } from "../../../domain/api";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Topbar } from "../../../component/Topbar";
import { Content } from "./styles";
import { Http2ServerResponse } from "http2";
import { DialogTitle, Hidden } from "@material-ui/core";
import { table } from "console";
import { number } from "prop-types";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

interface Item {
  match: any;

  classroomId: string;
  essayId: number;
  data: Date;
  topRightText?: string;
  token: string;
  footer: string;
  title: string;
  description: string;
  topRight: string;
  topLeft: string;
  classroom: string;
  essayToken: string;
  history: {
    location: {
      state: {
        classroomId: string;
        essayId: string;
      };
    };
  };
}

interface Student {
  cgm: number;
  studentId: number;
  classroomDescription: string;
  email: string;
  essayLinked: boolean;
  name: string;
  statusDescription: number;
  status: number;
  token: string;
  essayId: number;
  classroom: string;
  classroomId: number;
  essayToken: string;
  essayClassId: string;
  score: string;
  reviewScore: string;
}

export function ClassroomItem(props: Item) {
  const [studentsModal, setStudentsModal] = useState(false);
  const [students, setStudents] = useState<Student[]>([]);
  const [name, setName] = useState();
  const setarNome = "";
  let history = useHistory();

  function handleCloseDialog() {
    setStudentsModal(false);
  }

  const exportToXLSX = () => {
    const students = Array.from(document.querySelectorAll("#tbody tr")).map(
      (row) => {
        const name = row.querySelector("#tbodyName")?.textContent || "";
        const stage = row.querySelector("#tbodyStage")?.textContent || "";
        const gradeText = row.querySelector("#tbodyGrade")?.textContent || "";
        const [notaText, totalText] = gradeText.split("/");

        const nota = notaText.trim().replace("**", "");
        const total = totalText.trim();

        return [name, stage, nota, total];
      }
    );

    const header = ["NOME", "ETAPA", "NOTA", "TOTAL"];
    const data = [header, ...students];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Planilha 1");
    const filename =
      props.history.location.state.classroomId + " - " + name + ".xlsx";
    XLSX.writeFile(workbook, filename);
  };

  useEffect(() => {
    apiSecure
      .get("/Essay/teacher/l/student/" + props.history.location.state.essayId, {
        params: {
          classroomId: props.history.location.state.classroomId,
        },
      })
      .then(function (response: any) {
        setStudents(response.data);
      })
      .catch(function () {});
  }, []);

  useEffect(() => {
    apiSecure
      .get(
        "/TeacherSchool/getClassByClassroomId/" +
          props.history.location.state.classroomId
      )
      .then(function (response: any) {
        setName(response.data[0].description);
      })
      .catch(function () {});
  }, []);

  return (
    <>
      <Topbar profile="10" menu="home"></Topbar>

      <div
        style={{
          margin: "2% 1% 0 1%",
          backgroundColor: "var(--blue-light)",
          color: "var(--white)",
          width: "98%",
          padding: "1%",
          borderTopLeftRadius: "0.25rem",
          borderTopRightRadius: "0.25rem",
          overflowX: "hidden",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            fontSize: "25px",
          }}
        >
          CÓD:&nbsp;&nbsp;{props.history.location.state.classroomId}
          <br></br>
          TURMA:&nbsp;&nbsp;{name}
        </h3>
      </div>

      <Content>
        {/* Botão para exportar a tabela para XLSX */}
        {students.length > 0 && (
          <button
            style={{
              display: "block",
              background: "var(--white)",
              color: "var(--blue-light)",
              border: "2px solid var(--blue-light)",
              margin: "2% 30%",
              width: "100%",
              height: "3rem",
              borderRadius: "0.25rem",
              fontSize: "100%",
              fontWeight: "bold",
            }}
            onClick={exportToXLSX}
          >
            EXPORTAR NOTAS
          </button>
        )}
        {students.length > 0 && (
          <table
            id="students-table"
            style={{
              backgroundColor: "var(--blue-light)",
              borderTopLeftRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
            }}
          >
            <thead id="thead">
              <tr>
                <th id="theadName">NOME</th>
                <th id="theadStage">ETAPA</th>
                {/* <th id="theadC1">C1</th>
                <th id="theadC2">C2</th>
                <th id="theadC3">C3</th>
                <th id="theadC4">C4</th>
                <th id="theadC5">C5</th> */}
                <th id="theadGrade">NOTA</th>
                <th id="theadAction">AÇÃO</th>
              </tr>
            </thead>
          </table>
        )}

        {students.length > 0 &&
          students.map((e) => (
            <StudentItem
              key={e.cgm}
              cgm={e.cgm}
              essayId={props.essayId}
              studentId={e.studentId}
              classroom={e.classroomDescription}
              classroomId={e.classroomId}
              email={e.email}
              essayLinked={e.essayLinked}
              name={e.name.toUpperCase()}
              statusDescription={e.statusDescription}
              status={e.status}
              essayToken={props.match.params.tokenStudent}
              essayClassId={props.history.location.state.essayId}
              token={e.token}
              reviewScore={e.reviewScore}
              score={e.score}
            />
          ))}

        {students.length === 0 && (
          <p style={{ marginTop: "3rem;", textAlign: "center", width: "100%" }}>
            Não existem alunos para a turma selecionada.
          </p>
        )}

        <button
          style={{
            display: "block",
            background: "var(--green)",
            color: "var(--white)",
            margin: "2% 30%",
            width: "100%",
            height: "3rem",
            border: "none",
            borderRadius: "0.25rem",
            fontSize: "100%",
            fontWeight: "bold",
          }}
          onClick={() =>
            history.push({
              pathname: "/class/" + props.history.location.state.classroomId,
              state: { classroomId: props.history.location.state.classroomId },
            })
          }
        >
          VOLTAR
        </button>
      </Content>
      <div style={{ marginBottom: "10px" }}>&nbsp;</div>
    </>
  );
}

const StudentItem = (props: Student) => {
  const [atribuir, setAtribuir] = useState(false);
  const [readOnly, setReadOnly] = useState(localStorage.getItem("IsReadOnly"));
  let history = useHistory();
  useEffect(() => {
    if (atribuir === true) {
      apiSecure
        .post(
          "Essay/link/" +
            props.essayClassId +
            "/" +
            props.studentId +
            "/" +
            props.classroomId
        )
        .then(function () {
          setAtribuir(false);
          history.push({
            pathname:
              "/teacher/class/" +
              props.classroomId +
              "/student/" +
              props.essayToken,
            state: {
              classroomId: props.classroomId,
              essayId: props.essayClassId,
            },
          });
        })
        .catch(function (error: Http2ServerResponse) {
          setAtribuir(false);
          history.push({
            pathname:
              "/teacher/class/" +
              props.classroomId +
              "/student/" +
              props.essayToken,
            state: {
              classroomId: props.classroomId,
              essayId: props.essayClassId,
            },
          });
        });
    }
  }, [atribuir === true]);

  let textColor = "var(--blue-light)";
  let backgroundColor = "var(--blue-light)";
  let borderColor = "var(--green) var(--green)";
  let borderThickness = "0.5";
  let btnText = "";

  switch (props.status) {
    case 1:
      btnText = "";
      break;
    case 2:
      btnText = "";
      break;
    case 3:
      btnText = "";
      break;
    case 5:
      textColor = "var(--blue-light)";
      backgroundColor = "var(--white)";
      borderColor = "var(--blue-light)";
      btnText = "VISUALIZAR";
      break;
    case 4:
      if (readOnly) {
        textColor = "var(--white)";
        backgroundColor = "var(--green)";
        borderColor = "var(--green)";
        btnText = "CORRIGIR";
      }
      break;
    case 6:
      textColor = "var(--blue-light)";
      backgroundColor = "var(--white)";
      borderColor = "var(--blue-light)";
      btnText = "VISUALIZAR";
      break;
    default:
      break;
  }

  return (
    <table
      style={{
        border: "none",
        borderBottom: "1px solid var(--blue-light3)",
      }}
    >
      <tbody id="tbody">
        <tr>
          {/* NOME */}
          <th id="tbodyName">
            <h3>{props.name}</h3>
          </th>

          {/* ETAPA */}
          <th id="tbodyStage">
            <p style={{ fontWeight: "normal" }}>
              {props.statusDescription
                ? props.statusDescription
                : "Não atribuído"}
            </p>
          </th>
          {/* C1 */}
          {/* <th id="tbodyC1">
            {props.status === 6 && (
              <p style={{ fontWeight: "normal" }}>
                {" "}
                {Number(
                  Number(props.reviewScore) +
                    (Number(props.score) > 40
                      ? Number(props.score) * 0.4
                      : Number(props.score))
                ).toFixed(0)}{" "}
                / 100{" "}
              </p>
            )}
          </th> */}

          {/* C2 */}
          {/* <th id="tbodyC2">
            {props.status === 6 && (
              <p style={{ fontWeight: "normal" }}>
                {" "}
                {Number(
                  Number(props.reviewScore) +
                    (Number(props.score) > 40
                      ? Number(props.score) * 0.4
                      : Number(props.score))
                ).toFixed(0)}{" "}
                / 100{" "}
              </p>
            )}
          </th> */}
          {/* C3 */}
          {/* <th id="tbodyC3">
            {props.status === 6 && (
              <p style={{ fontWeight: "normal" }}>
                {" "}
                {Number(
                  Number(props.reviewScore) +
                    (Number(props.score) > 40
                      ? Number(props.score) * 0.4
                      : Number(props.score))
                ).toFixed(0)}{" "}
                / 100{" "}
              </p>
            )}
          </th> */}
          {/* C4 */}
          {/* <th id="tbodyC4">
            {props.status === 6 && (
              <p style={{ fontWeight: "normal" }}>
                {" "}
                {Number(
                  Number(props.reviewScore) +
                    (Number(props.score) > 40
                      ? Number(props.score) * 0.4
                      : Number(props.score))
                ).toFixed(0)}{" "}
                / 100{" "}
              </p>
            )}
          </th> */}
          {/* C5 */}
          {/* <th id="tbodyC5">
            {props.status === 6 && (
              <p style={{ fontWeight: "normal" }}>
                {" "}
                {Number(
                  Number(props.reviewScore) +
                    (Number(props.score) > 40
                      ? Number(props.score) * 0.4
                      : Number(props.score))
                ).toFixed(0)}{" "}
                / 100{" "}
              </p>
            )}
          </th> */}
          {/* NOTA */}
          <th id="tbodyGrade">
            {props.status === 6 && (
              <p style={{ fontWeight: "normal" }}>
                {" "}
                {Number(
                  Number(props.reviewScore) +
                    (Number(props.score) > 40
                      ? Number(props.score) * 0.4
                      : Number(props.score))
                ).toFixed(0)}{" "}
                / 100{" "}
              </p>
            )}

            {props.status != 6 && (
              <p style={{ color: "var(--background)", fontWeight: "normal" }}>
                ** / 100
              </p>
            )}
          </th>

          {/* ACAO */}
          <th id="tbodyAction">
            <Link
              to={{
                pathname: "/teacher/essay/correction/" + props.token,
                state: { studentId: props.studentId },
              }}
            >
              {btnText !== "" && props.essayLinked && (
                <button
                  style={{
                    marginTop: "0px",
                    padding: "2%",
                    width: "7rem",
                    borderRadius: "0.25rem",
                    background: backgroundColor,
                    borderWidth: borderThickness,
                    borderColor: borderColor,
                    color: textColor,
                    fontWeight: "bold",
                  }}
                >
                  {btnText}
                </button>
              )}
              {btnText === "" && ""}
            </Link>
            {!props.essayLinked && (
              <button
                style={{
                  marginTop: "0px",
                  padding: "2%",
                  width: "7rem",
                  borderRadius: "0.25rem",
                  background: "var(--blue-light)",
                  borderWidth: "0.5",
                  borderColor: "var(--blue-light)",
                  color: "var(--white)",
                  fontWeight: "bold",
                }}
                onClick={() => setAtribuir(true)}
              >
                ATRIBUIR
              </button>
            )}
          </th>
        </tr>
      </tbody>
    </table>
  );
};
