import React, { useState } from "react";
import { makeStyles, Typography, Paper, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "fontsource-roboto";
import Icon from "@material-ui/core/Icon";
import { Container } from "./styles";
import { BorderBottom } from "@material-ui/icons";

interface CardProps {
  visibility: boolean;
  redirectUrl: string;
  iconName?: string;
  iconColor?: string;
  leftTopTooltip?: string;
  leftTop?: string;
  rightTopTooltip?: string;
  rightTopSize?: number;
  rightTop?: string;
  description?: string;
  bottomLeft?: string;
  bottomRightTooltip?: string;
  bottomRight?: string;
  borderBottom: string;
}
export function Card(props: CardProps) {
  const classes = useStyles();
  let history = useHistory();

  return (
    <>
      <Container
        BorderBottom={props.borderBottom}
        onClick={() => history.push(props.redirectUrl)}
      >
        <div className={classes.topLine}>
          <div className={classes.leftTop}>
            {props.iconName && (
              <Icon
                fontSize={"small"}
                style={{
                  fontSize: "1rem",
                  color: props.iconColor,
                }}
              >
                {props.iconName}
              </Icon>
            )}

            <Tooltip title={props.leftTopTooltip ?? ""} aria-label="add">
              <span className={classes.topFont}>{props.leftTop}</span>
            </Tooltip>
          </div>

          <div className={classes.rightTop}>
            <Tooltip title={props.rightTopTooltip ?? ""} aria-label="add">
              <p
                className={classes.topFont}
                style={{ fontSize: props.rightTopSize ?? 13 }}
              >
                {props.rightTop}
              </p>
            </Tooltip>
          </div>
        </div>

        <div className={classes.middleLine}>
          <Typography variant={"body2"} className={classes.middleText}>
            {props.description}
          </Typography>
        </div>

        <div className={classes.bottomLine}>
          <Typography variant={"body2"} className={classes.smallFont}>
            {props.bottomLeft}
          </Typography>

          <div className={classes.bottomRight}>
            {props.bottomRightTooltip && (
              <Tooltip title={props.bottomRightTooltip} aria-label="add">
                <span className={classes.smallFont}>{props.bottomRight}</span>
              </Tooltip>
            )}
            {!props.bottomRightTooltip && (
              <span className={classes.smallFont}>{props.bottomRight}</span>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    "&:hover": {
      backgroundColor: "#f2f1f0",
    },
    cursor: "pointer",
    margin: 5,
    minHeight: 130,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #e2e2e2",
  },
  topLine: {
    width: "100%",
    marginBottom: 10,
    display: "flex",
    resize: "vertical",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  middleLine: {
    width: "100%",
    marginBottom: 10,
    minHeight: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    resize: "vertical",

    [theme.breakpoints.down("xs")]: {
      marginTop: 15,
    },
  },

  bottomLine: {
    width: "100%",
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: 15,
    },
  },

  rightTop: {
    color: "var(--green)",
    order: 2,
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10%",
    },
  },

  bottomRight: {
    marginLeft: "auto",
    order: 2,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  middleText: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // // height: 50,
    // resize: "vertical",
  },

  leftTop: {
    display: "flex",
    flexDirection: "row",
    height: 25,
    resize: "vertical",
    color: "var(--blue-dark)"
  },
  topFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "14px",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {},
  },

  smallFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#6d6d6d",
  },
}));
