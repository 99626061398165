import React, { useState, useEffect, useMemo } from "react";
import { Topbar } from "../../../component/Topbar";
import { Card } from "component/Card";
import HistoryLine from "../components/HistoryLine";
import moment from "moment";
import { GetPending } from "domain/essayTeacher/service";
import "moment/min/locales";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import { apiSecure } from "domain/api";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Widgets } from "@material-ui/icons";
import proposeIconBlue from "../../../../src/assets/proposeIcon.png";
import template from "../../../../src/public/template.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: "1%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

interface History {
  essayId: string;
  classroomId: string;
  token: string;
  classroomDescription: string;
  schoolDescription: string;
  genre: string;
  type: string;
  classroom: string;
  studentId: number;
  deliveredAt: Date;
  studentName: string;
  description: string;
  schoolName: string;
}

export default function TeacherHome() {
  let history = useHistory();

  return (
    <>
      <Topbar profile="10" menu="home"></Topbar>

      <GetMyThemes />
      <GetPendingList />
      <button
        className={"floatingActionButton"}
        onClick={() => history.push("/teacher/essay/new")}
      >
        PROPOR REDAÇÃO
      </button>
    </>
  );
}

function GetPendingList() {
  const [pending, setPending] = useState<History[]>([]);

  useEffect(() => {
    apiSecure
      .get("/Essay/teacher/l/pending")
      .then(function (response) {
        setPending(response.data);
      })
      .catch(function (error) {});

    return () => {};
  }, []);

  return (
    <>
      <GetPending callback={(e: History[]) => setPending(e)} />
      <div>
        <div
          style={{
            backgroundColor: "var(--blue-light)",
            margin: "1% 1% 0 1%",
            padding: "0.5%",
            borderTopLeftRadius: "0.25rem",
            borderTopRightRadius: "0.25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <h2 style={{ color: "var(--white)" }}>AGUARDANDO CORREÇÃO</h2>
          </center>
        </div>
        <Container>
          {pending.length > 0 &&
            pending.map((e) => (
              <Card
                borderBottom={"var(--blue-light)"}
                redirectUrl={"/teacher/essay/correction/" + e.token}
                rightTop={moment(e.deliveredAt)
                  .utc(true)
                  .utcOffset("-03:00")
                  .locale("pt-br")
                  .calendar()}
                leftTop={
                  "Entregue por " +
                  GetAbbreviatedName(e.studentName) +
                  " - " +
                  e.classroom +
                  " - " +
                  e.schoolName.slice(0, 25) +
                  "."
                }
                bottomLeft={`${e.type} - ${e.genre}`}
                description={e.description}
              />
            ))}
          {pending.length === 0 && (
            <>
              <div
                style={{
                  padding: "1% 0",
                  width: "100%",
                }}
              >
                <p>Nenhuma redação pendente de correção encontrada!</p>
                <br />
                <div
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    lineHeight: "30pt",
                  }}
                >
                  Você pode propor uma nova redação para seus estudantes
                  clicando em&nbsp;&nbsp;&nbsp;
                  <a
                    id="btnProporDesktop"
                    href="/teacher/essay/new"
                    style={{
                      textDecoration: "none",
                      color: "var(--white)",
                      backgroundColor: "var(--blue-light)",
                      padding: "0.8%",
                      borderRadius: "0.25rem",
                      width: "100%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>PROPOR&nbsp;REDAÇÃO</b>
                  </a>
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

function GetMyThemes() {
  const classes = useStyles();
  let link = useHistory();
  const [history, setHistory] = useState<History[]>([]);
  const [historyList, setHistoryList] = useState<boolean>(false);

  useEffect(() => {
    apiSecure
      .get("/Essay/teacher/l/classrooms/" + 1)
      .then(function (response) {
        setHistory(response.data);
        if (response.data.length === 0) {
          setHistoryList(true);
        }
        console.log("response.data", response.data);
      })
      .catch(function (error) {
        console.error(error);
      });

    return () => {};
  }, []);

  const memoizedValue = useMemo(() => history, [history]);

  const handleEvent = (event: any) => {
    console.log(event);
  };

  return (
    <>
      {/* <div style={{margin:'1%'}}>
        <img
          src={`${process.env.PUBLIC_URL}/img1.png`}
          alt="Descrição da imagem"
          style={{width:'100%', borderRadius:'0.28rem'}}
        />
      </div> */}
      <div>
        <div
          style={{
            backgroundColor: "var(--blue-light)",
            margin: "1% 1% 0 1%",
            padding: "0.5%",
            borderTopLeftRadius: "0.25rem",
            borderTopRightRadius: "0.25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <h2 style={{ color: "var(--white)" }}>MINHAS TURMAS</h2>
          </center>
        </div>
        <Container onMouseUp={(e) => handleEvent(e)}>
          {history.length > 0 &&
            memoizedValue.map((e: History) => (
              <HistoryLine
                essayId={e.essayId}
                classroomId={e.classroomId}
                token={e.token}
                key={e.token}
                studentId={e.studentId}
                description={e.classroomDescription}
                type={e.schoolDescription}
                genre={e.genre}
                school={e.schoolDescription}
                classroom={e.classroom}
                creationDate={e.deliveredAt}
              />
            ))}
        </Container>
      </div>
    </>
  );
}

function GetAbbreviatedName(str: string) {
  if (str == null || str === undefined || str === "") {
    return "";
  }
  let spaces = String(str).split(" ");
  if (spaces.length === 0) {
    return "";
  }

  let name = spaces[0];
  if (spaces.length === 1) {
    return name;
  }
  let lastName = spaces[1];
  let firstLetter = lastName.substr(0, 1);

  return name + " " + firstLetter + ".";
}
