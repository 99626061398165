import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Topbar } from "component/Topbar";
import Divider from "@material-ui/core/Divider";
import { languageApi, apiSecure } from "domain/api";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
  Hidden,
} from "@material-ui/core";
import Correction from "./component/Correction";
import ErrorList from "./component/ErrorList";
import qs from "querystring";
import CorrectionHistory from "./component/CorrectionHistory";
import DialogActions from "@material-ui/core/DialogActions";
import { GetDetails as GetEssayDetails } from "domain/essayTeacher/service";
import {
  GetDetails as GetThemeDetails,
  GetGenre,
  GetType,
  GetMotivatingText,
} from "domain/theme/service";
import { useHistory } from "react-router-dom";
import Descriptor2 from "./component/Descriptor2";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import PlagiarismResult from "./component/PlagiarismResult";
import { GetReviewByToken } from "domain/review/service";
import { BorderTopRounded } from "@material-ui/icons";
import loadingLogo from "../../../component/images/loader3.gif";

export default function Essay(props) {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [devolutive, setDevolutive] = useState("");
  const [openTeacherNote, setOpenTeacherNote] = useState(false);
  const [essayDetails, setEssayDetails] = useState();
  const [json, setJson] = useState({});
  const [finalScore, setFinalScore] = useState(0);
  const [run, setRun] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [sendDevolutive, setSendDevolutive] = useState(false);
  const [open, toggleOpen] = useState(false);
  const [theme, setTheme] = useState({});
  const [genre, setGenre] = useState();
  const [type, setType] = useState();
  const [motivatingTexts, setMotivatingTexts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDescriptors, setOpenDescriptors] = useState(false);
  const [remake, setRemake] = useState(false);
  const [finish, setFinish] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [descriptores, setDescriptors] = useState([]);
  const [Id, setId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();
  const [reviewDetails, setReviewDetails] = useState();
  const [readOnly, setReadOnly] = useState(localStorage.getItem("IsReadOnly"));

  let history = useHistory();
  function handleType(props) {
    const result = props.find((item) => item.id === theme.themeTypeId);
    setType(result.description);
  }

  function handleGenre(props) {
    const result = props.find((item) => item.id === theme.themeGenreId);
    setGenre(result.description);
  }

  useEffect(() => {
    if (remake && !finish > 0) {
      setOpenDialog(true);
    }
    if (finish && !remake) {
      setOpenDescriptors(true);
    }
  }, [remake, finish]);

  useEffect(() => {}, [finalScore]);
  useEffect(() => {
    if (essayDetails)
      if (essayDetails.score > 100)
        essayDetails.score = Number(essayDetails.score * 0.4).toFixed(0);

    if (essayDetails && essayDetails.text.length > 0) {
      setText(essayDetails.text);
      setTitle(essayDetails.title);
      if (
        essayDetails.languageToolObj === undefined ||
        essayDetails.languageToolObj === null
      ) {
        setRun(true);
      } else {
        setJson(essayDetails.languageToolObj);
        toggleOpen(true);
      }

      if (essayDetails.status !== 4) {
        setDisabled(true);
      }
    }
  }, [essayDetails]);

  useEffect(() => {
    setWordCount(0);
    if (text.length > 0) {
      setWordCount(
        text.trim().split(/\b[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{1,50}\b/)
          .length - 1
      );
    }
  }, [text]);
  useEffect(() => {
    if (theme.themeTypeId) {
      GetDescriptors();
    }

    function GetDescriptors() {
      apiSecure
        .get(
          "/Descriptor/type/" +
            theme.themeTypeId +
            "/genre/" +
            theme.themeGenreId
        )
        .then((response) => {
          setDescriptors(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [theme]);

  const [set, setset] = useState([]);
  useEffect(() => {
    if (descriptores && reviewDetails) {
      setDevolutive(reviewDetails.reviewText);
      let mydesc = [];
      descriptores.map((e) => {
        if (reviewDetails) {
          reviewDetails.result.map((r) => {
            if (r.category === e.category && r.id === e.id) {
              if (mydesc.find((element) => element.id == e.id)) {
              } else {
                mydesc.push({
                  category: e.category,
                  score: r.score,
                  maxScore: e.maxScore,
                  options: e.options,
                  typeId: e.typeId,
                  description: e.description,
                  category: e.category,
                  id: e.id,
                });
              }
            }
          });
        } else {
          setset(descriptores);
        }
      });

      setset(mydesc);

      console.log(set);
    } else {
      setset(descriptores);
    }
  }, [reviewDetails, descriptores]);

  useEffect(() => {
    var status = "";
    if (sendDevolutive === true) {
      setSendDevolutive(false);
      if (remake) {
        status = "RedoRequired";
      }
      sendReview();
    }

    function sendReview() {
      apiSecure
        .put("/Essay/teacher/" + props.match.params.token + "/review", {
          studentId: props.location.studentId,
          reviewScore: Number(
            essayDetails.reviewScore ? essayDetails.reviewScore : 0
          ),
          reviewText: devolutive,
          status: status,
          score: Number(essayDetails.score).toFixed(0),
        })
        .then((response) => {
          setJson(response.data);
          toggleOpen(true);
          setFinish(false);
          setRemake(false);
          history.push(
            "/teacher/essay/correction/" + props.match.params.token,
            {
              state: { studentId: props.location.studentId },
            }
          );
        })
        .catch((err) => {
          setRun(false);
          setFinish(false);
          setRemake(false);
        });
    }
  }, [sendDevolutive]);

  useEffect(() => {
    if (run === true) {
      setRun(false);
      checkText();
    }

    function checkText() {
      languageApi
        .post(
          "/check",
          qs.stringify({
            language: "pt-BR",
            text: title + "\n" + text,
          })
        )
        .then((response) => {
          setJson(response.data);
          toggleOpen(true);
        })
        .catch((err) => {
          setRun(false);
        });
    }
  }, [run]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClose();
  };

  const handleDialogConfirm = () => {
    setSendDevolutive(true);
    setOpenDialog(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setFinish(false);
    setRemake(false);
  };

  const handleChangeType1 = (value, e) => {
    setId([]);
    if (/[0-9]+$/.test(value.target.value)) console.info("AE");
    else {
      console.error("Nao");
    }
    if (value.target.value > e.maxScore) {
      value.target.value = e.maxScore;
    } else if (value.target.value < 0) {
      value.target.value = 0;
    } else {
      value.target.value = value.target.value;
    }
    var total = 0;
    var array = [];

    descriptores.map((ee) => {
      array.push({
        essayId: essayDetails.id,
        studentId: essayDetails.studentId,
        descriptorId: ee.id,
        descriptorScore: Number(document.getElementById(ee.id).value),
      });
      total = total + Number(document.getElementById(ee.id).value);
    });
    setId(array);

    setEssayDetails(() => ({
      ...essayDetails,
      reviewScore: total,
    }));
    console.log(essayDetails.reviewScore);
  };

  const handleChangeType2 = (DescriptorId, id, score) => {
    let array = Id.filter((e) => e.descriptorId !== DescriptorId);

    if (score >= 0) {
      let obj = {
        essayId: essayDetails.id,
        studentId: essayDetails.studentId,
        descriptorId: DescriptorId,
        descriptorOptionId: id,
        descriptorScore: score,
      };

      setId([...array, obj]);
    } else {
      setId(array);
    }
  };

  function assignGrade() {
    setFinish(true);
    document.getElementById("dvTheme").style.display = "none";
    document.getElementById("dvTextEvaluation").style.display = "block";
    document.getElementById("btnAssignGrade").style.display = "none";
    document.getElementById("btnRewrite").style.display = "none";
    document.getElementById("btnPopUpProposal").style.display = "block";
    document.getElementById("btnSendGrade").style.display = "block";
    document.getElementById("btnCancelAssignGrade").style.display = "block";
    document.getElementById("correctionHistory").style.display = "none";
  }

  function requestRewrite() {
    //setOpenTeacherNote(true);
    document.getElementById("openTeacherNote").style.display = "block";

    document.getElementById("btnAssignGrade").style.display = "none";
    document.getElementById("btnRewrite").style.display = "none";
    document.getElementById("correctionHistory").style.display = "none";
  }

  function cancelAssignGrade() {
    setFinish(false);
    //setOpenTeacherNote(false);
    document.getElementById("openTeacherNote").style.display = "none";
    document.getElementById("dvTheme").style.display = "block";
    document.getElementById("dvTextEvaluation").style.display = "none";
    document.getElementById("btnAssignGrade").style.display = "block";
    document.getElementById("btnRewrite").style.display = "block";
    document.getElementById("btnPopUpProposal").style.display = "none";
    document.getElementById("btnSendGrade").style.display = "none";
    document.getElementById("btnCancelAssignGrade").style.display = "none";
    document.getElementById("correctionHistory").style.display = "block";
  }

  function popUpProposal() {
    document.getElementById("dvPopUpProposal").style.display = "block";
  }

  function closePopUpProposal() {
    document.getElementById("dvPopUpProposal").style.display = "none";
  }

  let wMax1 = "70%";
  let wMax2 = "10%";
  let wMax3 = "15%";
  let direction = "row";
  let finalGradeBox = "var(--green)";
  let grade = 0;
  let isMobile = false;
  if (window.innerWidth <= 1280) {
    isMobile = true;
  }

  useEffect(() => {
    if (Id.length > 0) {
      let total = 0;

      Id.map((ee) => {
        total = total + Number(ee.descriptorScore);
      });

      setEssayDetails(() => ({
        ...essayDetails,
        reviewScore: total,
      }));
    }
  }, [Id]);

  const handleSendScore = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }

    apiSecure
      .put("/Essay/teacher/" + props.match.params.token + "/review", {
        studentId: props.location.studentId,
        reviewScore: Number(essayDetails.reviewScore).toFixed(0),
        reviewText: devolutive,
        score: Number(essayDetails.score).toFixed(0),
        status: "Finished",
        ReviewDetails: Id,
      })
      .then((response) => {
        setJson(response.data);
        toggleOpen(true);
        setFinish(false);
        setRemake(false);
        history.push("/teacher/essay/correction/" + props.match.params.token, {
          state: { studentId: props.location.studentId },
        });
      })
      .catch((err) => {
        setRun(false);
        setFinish(false);
        setRemake(false);
      });
  };

  // const teste = "\n         ";

  return (
    <>
      <GetEssayDetails
        token={props.match.params.token}
        callback={(e) => setEssayDetails(e)}
      />
      <GetReviewByToken
        token={props.match.params.token}
        callback={(e) => setReviewDetails(e)}
      />
      {essayDetails && (
        <>
          <GetThemeDetails
            id={essayDetails.themeId}
            callback={(e) => setTheme(e)}
          />
        </>
      )}

      {theme && theme.id > 0 && (
        <GetMotivatingText
          id={theme.id}
          callback={(e) => setMotivatingTexts(e)}
        />
      )}

      {theme && theme.themeTypeId && (
        <>
          <GetGenre callback={(e) => handleGenre(e)} />
          <GetType callback={(e) => handleType(e)} />
        </>
      )}
      {open == false && (
        // <CircularProgress size={50} style={{ margin: "auto" }} />
        <div
          style={{
            position: "fixed",
            margin: "auto",
            left: "65%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={loadingLogo}
            alt=""
            style={{
              objectFit: "cover",
              objectPosition: "center",
              position: "relative",
              borderRadius: "500rem",
              width: "15%",
              backgroundColor: "var(--background)",
            }}
          />
        </div>
      )}
      <Dialog open={open} aria-labelledby="form-dialog-title" fullScreen>
        {/* POPUP PROPOSTA */}
        <div id="dvPopUpProposal" style={{ display: "none" }}>
          <div
            className="popUpBg"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              width: "100%",
              height: "100vh",
              zIndex: "5000",
              position: "fixed",
              overflow: "hidden",
              cursor: "pointer",
            }}
            onClick={() => closePopUpProposal()}
          ></div>
          <div
            className={classes.motivatingTextsPopUp}
            style={{
              backgroundColor: "var(--white)",
              position: "fixed",
              zIndex: "5001",
              width: "96vw",
            }}
          >
            <div style={{ flexWrap: "wrap", display: "flex" }}>
              <Typography
                variant={"h6"}
                style={{
                  textTransform: "uppercase",
                  color: "var(--blue-light)",
                  width: "95%",
                }}
              >
                Tema:
              </Typography>
              <h3
                style={{
                  textTransform: "uppercase",
                  color: "var(--blue-light)",
                  width: "5%",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => closePopUpProposal()}
              >
                X
              </h3>
            </div>
            <Typography variant="subtitle1" component="subtitle1">
              {theme.title}
            </Typography>
            <Typography
              variant={"h6"}
              style={{
                marginTop: 15,
                textTransform: "uppercase",
                color: "var(--blue-light)",
              }}
            >
              Redação para:
            </Typography>
            <Typography variant="subtitle1" component="subtitle1">
              {type}
            </Typography>
            <Typography
              variant={"h6"}
              style={{
                marginTop: 15,
                textTransform: "uppercase",
                color: "var(--blue-light)",
              }}
            >
              Gênero textual:
            </Typography>
            <Typography variant="subtitle1" component="subtitle1">
              {genre}
            </Typography>
            <Typography
              variant={"h6"}
              style={{
                marginTop: 15,
                textTransform: "uppercase",
                color: "var(--blue-light)",
              }}
            >
              Proposta:
            </Typography>
            <div
              className="ql-editor"
              variant="subtitle1"
              component="subtitle1"
              style={{
                textAlign: "justify",
                height: "100%",
                minHeight: "25rem",
              }}
              dangerouslySetInnerHTML={{
                __html: essayDetails?.details ?? theme.description,
              }}
            />
            <hr
              style={{
                marginTop: 30,
                marginBottom: 30,
                height: 1,
                width: "100%",
              }}
            />

            {motivatingTexts.length > 0 && (
              <>
                <Typography
                  variant={"h6"}
                  style={{
                    marginTop: 15,
                    textTransform: "uppercase",
                    color: "var(--blue-light)",
                  }}
                >
                  Textos de Apoio
                </Typography>
                <div style={{ marginTop: 15, padding: "1.5%" }}>
                  {motivatingTexts.map((e) => (
                    <>
                      {e.isFile && (
                        <img
                          alt={e.id}
                          style={{
                            display: "block",
                            margin: "auto",
                            width: "100%",
                          }}
                          src={e.text}
                        />
                      )}
                      {!e.isFile && (
                        <div
                          style={{ textAlign: "justify", textIndent: 50 }}
                          dangerouslySetInnerHTML={{
                            __html: e.text,
                          }}
                        ></div>
                      )}
                      <Divider style={{ margin: 30 }} />
                    </>
                  ))}
                </div>
              </>
            )}
            <Divider />
          </div>
        </div>
        <Topbar menu="home" profile="10" style={{ position: "fixed" }} />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <div
          style={{
            textTransform: "uppercase",
            color: "var(--blue-light)",
            backgroundColor: "var(--white)",
            padding: "1%",
          }}
        >
          <center>
            <h4> Correção da redação de </h4>
            <h2> {essayDetails?.studentName ?? ""} </h2>
            <h4> {essayDetails?.classroomDescription ?? ""} </h4>
          </center>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: 10,
              }}
            >
              <Button
                className={classes.buttons}
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname:
                      "/teacher/class/" +
                      essayDetails.classroomId +
                      "/student/" +
                      essayDetails.essayToken,
                    state: {
                      classroomId: essayDetails.classroomId,
                      essayId: essayDetails.id,
                    },
                  })
                }
                style={{
                  background: "var(--blue-light)",
                  color: "var(--white)",
                  padding: "1%",
                }}
              >
                Retornar para a listagem de estudantes
              </Button>
            </div>
            <ErrorList json={json} />
            <div className={classes.wordCount}>
              {essayDetails && essayDetails.minAmountWords > 0 && (
                <Typography
                  variant={"subtitle2"}
                  className={classes.title}
                  style={{ float: "left" }}
                >
                  Mínimo de {essayDetails.minAmountWords} e máximo de{" "}
                  {essayDetails.maxAmountWords} palavras.
                </Typography>
              )}
              <Typography
                variant={"subtitle2"}
                className={classes.title}
                style={{ float: "right" }}
              >
                <span style={{ color: "darkred" }}>{wordCount}</span> palavras
                encontradas
              </Typography>
            </div>

            <div className={classes.container}>
              {/* REDACAO */}
              <div id="dvEssay" class={classes.dvEssayContent}>
                <DialogContentText>
                  <div class={classes.dvEssayTitle}>
                    <center>
                      <h3
                        style={{
                          textTransform: "uppercase",
                          color: "var(--white)",
                        }}
                      >
                        Texto do estudante
                      </h3>
                    </center>
                  </div>
                </DialogContentText>
                <div className={classes.inputArea}>
                  <Correction json={json} text={title + `\n` + text} />
                </div>
              </div>

              {/* TEMA */}
              <div id="dvTheme" class={classes.dvThemeContent}>
                <DialogContentText>
                  <div class={classes.dvThemeTitle}>
                    <center>
                      <h3
                        style={{
                          textTransform: "uppercase",
                          color: "var(--white)",
                        }}
                      >
                        PROPOSTA
                      </h3>
                    </center>
                  </div>
                </DialogContentText>
                <div className={classes.motivatingTexts}>
                  <Typography
                    variant={"h6"}
                    style={{
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Tema:
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    {theme.title}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    style={{
                      marginTop: 15,
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Redação para:
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    {type}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    style={{
                      marginTop: 15,
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Gênero textual:
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    {genre}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    style={{
                      marginTop: 15,
                      textTransform: "uppercase",
                      color: "var(--blue-light)",
                    }}
                  >
                    Proposta:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="subtitle1"
                    className="ql-editor"
                    style={{ textAlign: "justify", minHeight: "25rem" }}
                    dangerouslySetInnerHTML={{
                      __html: essayDetails?.details ?? theme.description,
                    }}
                  ></Typography>
                  <hr
                    style={{
                      marginTop: 30,
                      marginBottom: 30,
                      height: 1,
                      width: "100%",
                    }}
                  />

                  {motivatingTexts.length > 0 && (
                    <>
                      <Typography
                        variant={"h6"}
                        style={{
                          marginTop: 15,
                          textTransform: "uppercase",
                          color: "var(--blue-light)",
                        }}
                      >
                        Textos de Apoio
                      </Typography>
                      <div style={{ marginTop: 15, padding: "1.5%" }}>
                        {motivatingTexts.map((e) => (
                          <>
                            {e.isFile && (
                              <img
                                alt={e.id}
                                style={{
                                  display: "block",
                                  margin: "auto",
                                  width: "100%",
                                }}
                                src={e.text}
                              />
                            )}
                            {!e.isFile && (
                              <div
                                style={{ textAlign: "justify", textIndent: 50 }}
                                dangerouslySetInnerHTML={{
                                  __html: e.text,
                                }}
                              ></div>
                            )}
                            <Divider style={{ margin: 30 }} />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  <Divider />
                </div>
              </div>

              {/* AVALIACAO */}
              <div id="dvTextEvaluation" className={classes.dvTextEvaluation}>
                {openDescriptors && descriptores && essayDetails && (
                  <>
                    <DialogContentText>
                      <div className={classes.dvTextEvaluationTitle}>
                        <center>
                          <h3
                            style={{
                              textTransform: "uppercase",
                              color: "var(--white)",
                            }}
                          >
                            Avaliar redação
                          </h3>
                        </center>
                      </div>
                    </DialogContentText>
                    <div className={classes.dvTextEvaluationContent}>
                      {descriptores[0]?.typeId === 1 && (
                        <>
                          <div style={{ marginBottom: "2%" }}>
                            <center>
                              <h4
                                style={{
                                  textTransform: "uppercase",
                                  color: "var(--blue-light)",
                                }}
                              >
                                Atribua uma nota para os critérios abaixo
                              </h4>
                            </center>
                          </div>
                        </>
                      )}
                      {descriptores[0]?.typeId === 2 && (
                        <>
                          <div style={{ display: "none" }}>
                            {(wMax1 = wMax2 = wMax3 = "100%")}
                            {(direction = "column")}
                          </div>
                          <div>
                            <div style={{ marginBottom: "2%" }}>
                              <center>
                                <h4
                                  style={{
                                    textTransform: "uppercase",
                                    color: "var(--blue-light)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Atribua uma nota selecionando um dos critérios
                                  para cada tipo de competência abaixo
                                </h4>
                              </center>
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid #c1c1c1",
                                marginTop: 50,
                              }}
                            />
                          </div>
                        </>
                      )}
                      {isMobile && (
                        <div style={{ display: "none" }}>
                          {(direction = "column")}
                          {(wMax1 = wMax2 = wMax3 = "100%")}
                        </div>
                      )}
                      {/* NOTA DA PLATAFORMA */}
                      <div className={classes.descriptorBox}>
                        <div className={classes.descriptorTitle}>
                          <strong
                            style={{
                              color: "var(--blue-light)",
                              textTransform: "uppercase",
                              fontSize: "14pt",
                            }}
                          >
                            Nota da Plataforma
                          </strong>{" "}
                          <p
                            style={{
                              color: "var(--blue-dark)",
                              textAlign: "justify",
                            }}
                          >
                            Nota atribuída ao aluno pela correção automática da
                            plataforma{" "}
                          </p>
                        </div>

                        <div className={classes.descriptorScore}>
                          <p
                            style={{
                              color: "var(--blue-light)",
                              marginBottom: "1%",
                            }}
                          >
                            0 a 40
                          </p>
                        </div>
                        <div className={classes.descriptorOption}>
                          <p>
                            <TextField
                              variant={"outlined"}
                              type={"number"}
                              label="Nota"
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: 40,
                                  "aria-label": "weight",
                                },
                              }}
                              className={classes.descriptorGrade}
                              value={
                                essayDetails.score > 40
                                  ? Number(essayDetails.score * 0.4).toFixed(0)
                                  : Number(essayDetails.score).toFixed(0)
                              }
                              onChange={(e) =>
                                setEssayDetails({
                                  ...essayDetails,
                                  score:
                                    e.target.value > 40 ? 40 : e.target.value,
                                })
                              }
                            ></TextField>
                          </p>
                        </div>
                      </div>

                      {/* NOTA DO PROFESSOR */}
                      {set.map((e) => (
                        <div
                          key={e.descriptorId}
                          className={classes.descriptorBox}
                          style={{ flexDirection: direction }}
                        >
                          <div
                            className={classes.descriptorTitle}
                            style={{ width: wMax1 }}
                          >
                            <strong
                              style={{
                                color: "var(--blue-light)",
                                textTransform: "uppercase",
                                fontSize: "14pt",
                              }}
                            >
                              {e.category}
                            </strong>{" "}
                            <p
                              style={{
                                color: "var(--blue-dark)",
                                textAlign: "justify",
                              }}
                            >
                              {e.description}
                            </p>
                          </div>
                          <div
                            className={classes.descriptorScore}
                            style={{ width: wMax2 }}
                          >
                            <p
                              style={{
                                color: "var(--blue-light)",
                                marginTop: "1%",
                                marginBottom: "1%",
                              }}
                            >
                              0 a {e.maxScore}
                            </p>
                          </div>
                          <div
                            className={classes.descriptorOption}
                            style={{ width: wMax3 }}
                          >
                            {e.typeId === 1 && (
                              <TextField
                                variant="outlined"
                                label="Nota"
                                type={"number"}
                                id={e.id}
                                className={classes.descriptorGrade}
                                onChange={(value) => {
                                  handleChangeType1(value, e);
                                }}
                                defaultValue={e.score}
                                inputProps={{
                                  min: "0",
                                  max: e.maxScore,
                                  step: "1",
                                }}
                                pattern="[0-9]*"
                              ></TextField>
                            )}

                            {e.typeId === 2 && (
                              <Descriptor2
                                e={e}
                                handleChange={(id, a, b) =>
                                  handleChangeType2(id, a, b)
                                }
                              />
                            )}
                          </div>
                        </div>
                      ))}
                      <TextField
                        label="Devolutiva"
                        helperText="* Esta resposta não é obrigatória"
                        multiline
                        //disabled={disabled === true && true}
                        rows={10}
                        variant="outlined"
                        value={devolutive}
                        onChange={(e) => setDevolutive(e.target.value)}
                        className={classes.devolutive}
                      ></TextField>
                      <div className={classes.reviewScoreBox}>
                        <Typography>
                          <strong
                            style={{
                              textTransform: "uppercase",
                              color: "var(--blue-light)",
                            }}
                          >
                            Nota do professor:&nbsp;
                            {Number(essayDetails.reviewScore).toFixed(0) > 0
                              ? Number(essayDetails.reviewScore).toFixed(0)
                              : "**"}{" "}
                            &nbsp;/&nbsp;60
                          </strong>
                        </Typography>

                        <Typography
                          style={{
                            textTransform: "uppercase",
                            color: "var(--blue-light)",
                          }}
                        >
                          Nota da plataforma:&nbsp;
                          {essayDetails.score > 40
                            ? Number(essayDetails.score * 0.4).toFixed(0)
                            : essayDetails.score}
                          &nbsp;/&nbsp;40
                        </Typography>
                        <br></br>

                        <div style={{ display: "none" }}>
                          {Number(essayDetails.reviewScore) +
                            Number(essayDetails.score) >=
                          60
                            ? (finalGradeBox = "var(--green)")
                            : (finalGradeBox = "var(--red)")}
                        </div>

                        <div
                          style={{
                            backgroundColor: finalGradeBox,
                            padding: "3%",
                            borderRadius: 5,
                          }}
                        >
                          <Typography
                            style={{
                              textTransform: "uppercase",
                              color: "var(--white)",
                            }}
                          >
                            <h3>Nota Final</h3>
                            <h1 style={{ color: "white" }}>
                              {Number(essayDetails.reviewScore) +
                                Number(
                                  essayDetails.score > 40
                                    ? Number(essayDetails.score * 0.4).toFixed(
                                        0
                                      )
                                    : essayDetails.score
                                ) >
                              0
                                ? Number(essayDetails.reviewScore) +
                                  Number(
                                    essayDetails.score > 40
                                      ? Number(
                                          essayDetails.score * 0.4
                                        ).toFixed(0)
                                      : essayDetails.score
                                  )
                                : "**"}
                            </h1>
                          </Typography>
                        </div>
                      </div>

                      {openDescriptors === true && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              margin: 10,
                            }}
                          ></div>

                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              id="openTeacherNote"
              style={{ width: "98%", margin: "1%", display: "none" }}
            >
              <div
                style={{
                  width: "98%",
                  margin: "1%",
                  backgroundColor: "var(--blue-light)",
                  color: "var(--white)",
                  padding: "1%",
                  borderTopLeftRadius: "0.25rem",
                  borderTopRightRadius: "0.25rem",
                }}
              >
                <center>
                  <h3>SOLICITAR REESCRITA</h3>
                </center>
              </div>
              <TextField
                label="Devolutiva *"
                helperText="Insira uma devolutiva para liberar o botão"
                multiline
                //disabled={disabled === true && true}
                rows={10}
                variant="outlined"
                onChange={(e) => setDevolutive(e.target.value)}
                style={{ width: "98%", margin: "1%" }}
              ></TextField>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    //setOpenDescriptors(false);
                    //setOpenTeacherNote(false);
                    //setFinish(false);
                    cancelAssignGrade();
                  }}
                  style={{ padding: "1%" }}
                >
                  Cancelar
                </Button>

                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  //disabled={disabled || loading || devolutive.length < 3} //Removido para aceitar reavaliaçao de redaçoes relacionadas ao agrinho 2023
                  disabled={loading || devolutive.length < 3}
                  title="Digite algo para habilitar o botão"
                  onClick={() => {
                    setRemake(true);
                  }}
                  style={{
                    backgroundColor: "var(--blue-light)",
                    color: "var(--white)",
                    borderRadius: "2px",
                    padding: "1%",
                  }}
                >
                  Solicitar reescrita
                </Button>
              </div>
            </div>

            {/* <PlagiarismResult token={props.match.params.token} /> */}

            <div id="buttonsDefault">
              <div className={classes.buttonsDefault}>
                {/* {readOnly === "false" ? ( */}
                <Button
                  className={classes.buttons}
                  id={"btnRewrite"}
                  variant="contained"
                  onClick={() => requestRewrite()}
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--blue-light)",
                    border: "2px solid var(--blue-light)",
                    padding: "1%",
                  }}
                >
                  Solicitar reescrita
                </Button>

                {/* <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  // disabled={loading || devolutive.length < 3}
                  disabled="false"
                  title="Digite algo para habilitar o botão"
                  onClick={() => {
                    setRemake(true);
                  }}
                  style={{
                    backgroundColor: "var(--blue-light)",
                    color: "var(--white)",
                    borderRadius: "2px",
                    padding: "1%",
                  }}
                >
                  ENVIAR PARA CONCURSO
                </Button>
                 */}
                {/* ) : (
									<></>
								)} */}
                <Button
                  className={classes.buttons}
                  id={"btnCancelAssignGrade"}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    //setOpenDescriptors(false);
                    cancelAssignGrade();
                  }}
                  style={{
                    display: "none",
                    padding: "1%",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnPopUpProposal"}
                  variant="contained"
                  style={{
                    backgroundColor: "var(--white)",
                    color: "var(--blue-light)",
                    border: "2px solid var(--blue-light)",
                    display: "none",
                    padding: "1%",
                  }}
                  onClick={() => {
                    popUpProposal();
                  }}
                >
                  Visualizar Proposta
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnSendGrade"}
                  variant="contained"
                  onClick={() => {
                    handleSendScore();
                  }}
                  disabled={loading || Id.length < 4}
                  title="Enviar correção"
                  style={{
                    display: "none",
                    background: "var(--green)",
                    color: "var(--white)",
                    padding: "1%",
                  }}
                >
                  Enviar e Concluir
                </Button>
                <Button
                  className={classes.buttons}
                  id={"btnAssignGrade"}
                  variant="contained"
                  onClick={() => {
                    assignGrade();
                  }}
                  // disabled={readOnly === true}
                  style={{
                    display: "inherit", //readOnly === "true" ? "none" : "inherit",
                    background: "var(--green)",
                    color: "var(--white)",
                    padding: "1%",
                  }}
                >
                  Atribuir nota
                </Button>

                <Button
                  className={classes.buttons}
                  variant="contained"
                  onClick={() =>
                    history.push({
                      pathname:
                        "/teacher/class/" +
                        essayDetails.classroomId +
                        "/student/" +
                        essayDetails.essayToken,
                      state: {
                        classroomId: essayDetails.classroomId,
                        essayId: essayDetails.id,
                      },
                    })
                  }
                  // disabled={readOnly === true}
                  style={{
                    display: "inherit", //readOnly === "true" ? "none" : "inherit",
                    background: "var(--blue-light)",
                    color: "var(--white)",
                    padding: "1%",
                  }}
                >
                  Retornar para a listagem de estudantes
                </Button>
              </div>
            </div>

            {essayDetails && !openTeacherNote && (
              <>
                <div id="correctionHistory">
                  <div
                    style={{
                      backgroundColor: "var(--blue-light)",
                      borderTopLeftRadius: "0.25rem",
                      BorderTopRightRadius: "0.25rem",
                      padding: "1%",
                      marginTop: "1.5rem",
                      marginBottom: "1%",
                    }}
                  >
                    <center>
                      <Typography
                        variant={"h6"}
                        style={{
                          color: "var(--white)",
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        Histórico de correções
                      </Typography>
                    </center>
                  </div>
                  <CorrectionHistory token={props.match.params.token} />
                </div>
              </>
            )}

            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                style={{ color: "var(--blue-light)" }}
              >
                {"DESEJA CONCLUIR A CORREÇÃO?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Ao enviar a correção não será possível realizar alterações.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDialogClose}
                  style={{ color: "var(--red)", padding: "1%" }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleDialogConfirm}
                  style={{ color: "var(--green)", padding: "1%" }}
                >
                  Concluir
                </Button>
              </DialogActions>
            </Dialog>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  descriptorBox: {
    margin: "1% 0",
    display: "flex",
    padding: "20px auto",
    minHeight: "30px",
    borderBottom: "1px solid #e8e8e8",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
    "@media(max-width: 1280px)": {
      width: "100%",
      flexDirection: "column",
      textJustify: "center",
    },
  },
  descriptorTitle: {
    width: "70%",
    textJustify: "center",
    whiteSpace: "break-spaces",
    marginLeft: 10,
    margin: "auto",
    fontSize: "14pt",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 20,
    },
    "@media(max-width: 1280px)": {
      width: "100%",
      margin: 0,
      fontSize: "16pt",
    },
  },
  descriptorScore: {
    width: "10%",
    textAlign: "center",
    margin: "auto",
    minWidth: 45,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "@media(max-width: 600px)": {
      textJustify: "center",
      margin: "1%",
    },
    "@media (max-width:1280px)": {
      margin: "1%",
      width: "94vw",
      minHeight: "0",
      maxHeight: "none",
    },
  },
  descriptorOption: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "@media (max-width:1280px)": {
      width: "100%",
      minHeight: "0",
      maxHeight: "none",
    },
  },
  reviewScoreBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fafafa",
    border: "1px solid var(--blue-light)",
    borderRadius: 5,
    minWidth: 400,
    textAlign: "center",
    padding: 20,
    margin: "50px auto 10px auto",
    [theme.breakpoints.down("md")]: {
      minWidth: 100,
    },
    width: "100%",
    marginTop: "3%",
    "@media(max-width: 1280px)": {
      width: "95%",
    },
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: "35%",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      minHeight: "35%",
      margin: "auto",
    },
  },
  root: {
    display: "flex",
    height: "50%",
    margin: "1%",
  },
  top: {
    display: "flex",
    flexDirection: "column",
    height: "60%",
    maxHeight: "600px",
    margin: theme.spacing(2),
    overflow: "scroll",
  },
  motivatingTextsPopUp: {
    [theme.breakpoints.down("md")]: {
      order: 1,
      minHeight: 300,
      width: "100%",
      margin: "20px auto",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "700px",
    maxHeight: "700px",
    border: " 1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "2vw",
    overflowY: "scroll",
    alignSelf: "flex-start",
    width: "96%",
    "@media(max-width: 600px)": {
      width: "98%",
      margin: 0,
    },
    "@media (max-width:1280px)": {
      width: "98vw",
      margin: "20px",
      minHeight: "0",
      maxHeight: "98vh",
    },
  },

  motivatingTexts: {
    [theme.breakpoints.down("md")]: {
      order: 1,
      minHeight: 300,
      width: "100%",
      margin: "20px auto",
    },
    display: "flex",
    flexDirection: "column",
    minHeight: "590px",
    maxHeight: "590px",
    border: " 1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "24px 20px",
    overflowY: "scroll",
    alignSelf: "flex-start",
    width: "96%",
    "@media(max-width: 600px)": {
      width: "98%",
      margin: 0,
    },
    "@media (max-width:1280px)": {
      width: "94vw",
      //margin: "20px 4vw",
      minHeight: "0",
      maxHeight: "98vh",
    },
  },

  inputArea: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "24px 20px",
    alignSelf: "flex-start",
    width: "98%",
    overflowY: "scroll",
    minHeight: "590px",
    maxHeight: "590px",

    "@media (max-width:1280px)": {
      width: "94vw",
      margin: "20px 0",
      minHeight: "0",
      maxHeight: "none",
    },
  },

  devolutive: {
    width: "100%",
    margin: "2.5%",
    "@media (max-width:1280px)": {
      width: "95%",
    },
  },

  wordCount: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      marginBottom: "3%",
    },
    width: "98%",
    margin: "auto",
    marginTop: 30,
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    border: "1px  solid lightgray",
    borderRadius: 5,
  },
  sidebar: {
    margin: "1%",
    maxWidth: "30%",
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "3%",
      margin: "5px",
      padding: "1px",
    },
    display: "flex",
    margin: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    margin: theme.spacing(3),
  },
  textarea: {
    margin: "1%",
    minWidth: "60%",
    minHeight: "600px",
  },
  title: {
    margin: "1%",
    minWidth: "10%",
    "@media(max-width: 1280px)": {
      fontSize: "16pt",
    },
  },
  typography: {
    margin: "1%",
    maxWidth: "100px",
    minHeight: "250px",
    justifyContent: "right",
    alignItems: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    display: "flex",
    margin: "10px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  dvTextEvaluation: {
    margin: "1% 1% 0 0",
    width: "50%",
    display: "none",

    "@media(max-width: 1280px)": {
      width: "94vw",
      padding: "0%",
      fontSize: "16pt",
    },
  },
  dvTextEvaluationTitle: {
    backgroundColor: "var(--blue-light)",
    borderTopRightRadius: "0.25rem",
    padding: "1%",
    "@media(max-width: 1280px)": {
      width: "94vw",
      borderTopLeftRadius: "0.25rem",
      padding: "1%",
    },
  },
  dvTextEvaluationContent: {
    width: "96%",
    overflowY: "scroll",
    maxHeight: "590px",
    border: " 1px solid #bfbfbf",
    borderRadius: "5px",
    padding: 20,
    margin: "24px 20px",

    "@media(max-width: 1280px)": {
      width: "100%",
      margin: "0",
      padding: "2% 2%",
      flexDirection: "center",
      fontSize: "14pt",
    },
    "@media (max-width:1280px)": {
      width: "94vw",
      margin: "20px 0",
      minHeight: "0",
      maxHeight: "none",
    },
  },
  dvEssayTitle: {
    backgroundColor: "var(--blue-light)",
    borderTopLeftRadius: "0.25rem",
    padding: "1%",

    "@media(max-width: 1280px)": {
      borderTopRightRadius: "0.25rem",
      marginTop: "1% 0",
      width: "100%",
    },
  },
  dvEssayContent: {
    width: "50%",
    margin: "1% 0 0 1%",

    "@media(max-width: 1280px)": {
      width: "94vw",
      margin: "0",
      padding: "0",
      fontSize: "16pt",
    },
  },
  dvThemeTitle: {
    backgroundColor: "var(--blue-light)",
    borderTopRightRadius: "0.25rem",
    padding: "1%",
    "@media(max-width: 1280px)": {
      borderTopLeftRadius: "0.25rem",
      width: "94vw",
    },
  },
  dvThemeContent: {
    width: "50%",
    margin: "1% 1% 0 0",

    "@media(max-width: 1280px)": {
      width: "94vw",
      margin: "0",
      padding: "0",
      fontSize: "16pt",
    },
  },
  descriptorGrade: {
    marginTop: "auto",
    marginBottom: "auto",
    minWidth: "5%",
    "@media(max-width: 1280px)": {
      minWidth: "10vw",
      marginLeft:"2.5%"
    },
  },
  buttonsDefault: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 10,
    "@media(max-width: 600px)": {
      display: "block",
      flexDirection: "column",
    },
  },
  "@global": {
    ".MuiDialogContent-root": {
      overflowY: "unset",
    },
  },
}));
