import React from "react";
import api from "domain/api";
import { isAuthenticated, getProfileId } from "domain/auth/AuthService";
import { CircularProgress } from "@material-ui/core";
import loading from "../component/images/loader3.gif";
import error from "../assets/error.png";
export function goHome() {
  var profileId = getProfileId();
  if (profileId === 11) {
    return "/teacher/home";
  }
  if (profileId === 10) {
    return "/teacher/home";
  }
  if (profileId === 8) {
    return "/student/home";
  }
  return "/login";
}
interface Props {
  location: {
    hash: {
      length: {};
    };
  };
}

export function Home(props: Props) {
  if (props.location.hash.length > 0) {
    var accesstoken = String(props.location.hash)
      .split("&")[1]
      .replace("access_token=", "");
    if (accesstoken) {
      api
        .post("Auth/login", {
          token: accesstoken,
          loginProvider: "web",
        })
        .then(function (r) {
          localStorage.setItem("Token", r.data.accessToken);
          localStorage.setItem("ProfileId", r.data.profileId);
          localStorage.setItem("RefreshToken", r.data.refreshToken);
          localStorage.setItem("ExpiresAt", r.data.expiresAt);
          // localStorage.setItem("Picture", r.data.picture);
          localStorage.setItem("Name", r.data.name);
          localStorage.setItem("IsReadOnly", r.data.readOnly);

          let path = goHome();
          console.log(path);
          window.location.href = path;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  } else {
    if (localStorage.getItem("Picture") || localStorage.getItem("Name")) {
      window.location.href = "/logout";
    }
    if (isAuthenticated()) {
      let path = goHome();
      window.location.href = path;
    } else {
      window.location.href = "/login";
    }
  }
  return (
    <>
      {/* LOADING */}
      <div
        style={{
          position: "fixed",
          display: "absolute",
          backgroundColor: "var(--background)",
          zIndex: "50000",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          id="dvLoading"
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <center>
            <img src={loading} alt="CARREGANDO" width="15%" />
          </center>
        </div>

        {/* ERROR */}
        <div
          id="dvError"
          style={{
            display: "none",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <center>
            <div>
              <img src={error} alt="REDAÇÃO PARANÁ" width="20%" />
            </div>
            <br />
            <br />
            <h1 style={{ color: "#0F4665", fontWeight: "bold" }}>ATENÇÃO!</h1>
            <br />
            <h2 style={{ color: "#0F4665", fontWeight: "bold" }}>
              NÃO FOI POSSÍVEL EFETUAR O SEU LOGIN.
            </h2>
            <br />
            <h2 style={{ color: "#0F4665", fontWeight: "bold" }}>
              CONTATE UM EMBAIXADOR.
            </h2>
            <br />
            <a href="javascript:history.go(-2)">
              <button
                style={{
                  marginTop: "5%",
                  border: "1px solid #36A837",
                  borderRadius: "0.25rem",
                  background: "#36A837",
                  padding: "10px",
                }}
              >
                <h2 style={{ color: "#FFF" }}>VOLTAR AO LOGIN</h2>
              </button>
            </a>
          </center>
        </div>
      </div>
    </>
  );
}
const timeout = window.setTimeout(sendError, 20000);
function sendError() {
  const dvLoading = document.getElementById("dvLoading");
  const dvError = document.getElementById("dvError");
  if (dvLoading != null && dvError != null) {
    dvLoading.style.display = "none";
    dvError.style.display = "block";
  }
}
